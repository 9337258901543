// sets a max length of 10 numbers
// filters out characters not allowed

export default {
  methods: {
    limitPhoneValues(e) {
      if (e) {
        let value = e.target.value;
        let charCode = e.charCode;
        let limit = 10;
        // max 10 characters
        if (value != undefined && value.toString().length >= limit) {
          e.preventDefault();
        }
        // not allowing characters 43(+), 45(-), 46(.), 69(E), 101(e)
        if (
          charCode == 43 ||
          charCode == 45 ||
          charCode == 46 ||
          charCode == 69 ||
          charCode == 101
        ) {
          e.preventDefault();
        }
      }
    }
  }
}